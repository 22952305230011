var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"colonyMap",style:({
    "width":  _vm.mapWidth ,
    "height":  _vm.mapHeight ,
    "background-image": "url(\"" + _vm.mapBg + "\")",
    "perspective": _vm.perspective,
  })},[_c('div',{staticClass:"grid grid-cols-8 gap-0 mapGrid",style:({
    "bottom": _vm.gridBottom,
    "transform": "rotateX("+_vm.rotateX+") scale("+_vm.scale+") skewX("+_vm.skewX+") translateX("+_vm.translateX+")",
    "width": _vm.gridWidth,
    "height": _vm.gridHeight,
  })},_vm._l((40),function(index){return _c('div',{key:index},[(_vm.hoods.find(function (h) { return h.location === index; }))?[_c('router-link',{staticClass:"w-full h-full block text-center flex items-center justify-center",attrs:{"to":'/neighborhood/' + _vm.hoods.find(function (h) { return h.location === index; }).id}})]:_vm._e()],2)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }