



























import Vue from 'vue';

export default Vue.extend({
  name: "colonyMap",
  props: [
    "mapWidth",
    "mapHeight",
    "mapBg",
    "perspective",
    "gridBottom",
    "rotateX",
    "scale",
    "skewX",
    "translateX",
    "gridWidth",
    "gridHeight",
    "hoods",
  ],
  data: () => {
    return {
    };
  },
  methods: {
  },
  mounted() {
  },
});
